*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-family: Arimo, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #2c2e2f;
    line-height: 1.42857143;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

a {
    color: #2c2e2f;
}

a[type="button"]{
    text-decoration: none;
    color: white;
}

/* removes blue background in inputs from autofill */
@-webkit-keyframes autofill {
    0%,100% {
        color: #666;
        background: transparent;
    }
}
input:-webkit-autofill {
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block
}




/* Forms
   ========================================================================== */
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
    overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
    text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
    box-sizing: border-box; /* 1 */
    /*color: inherit; !* 2 *!*/
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
    display: inline-block; /* 1 */
    vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */

textarea {
    overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

@keyframes spin {
    0% {
        transform: rotateZ(0);
    }
    100% {
        transform: rotateZ(360deg);
    }
}


[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */


 /*EMAIL TEMPLATE EDITOR*/
div.ql-editor[contenteditable="false"] {
    background-color: rgba(211,211,211,0.4); /*Changes opacity of quill wysiwyg editor if it's disabled*/
}

.ql-editor {
     height: 18rem !important;
}

.ql-container {
    height: 18rem !important;
}

::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
}

.imgpreview__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgpreview__container > img {
    padding: 0;
}

.imgpreview__label {
    font-weight: bold;
    position: absolute;
    top: 8px;
    left: 16px;
}

.imgpreview__reset-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-radius: 4px;
    background-color: red;
    color: white;
}

.imgpreview__reset-btn::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: 'X';
    color: white;
    transform: translate(-50%, -50%);
    font-weight: bold;
}


/*STATUS LIST*/
.status-list-panel {
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    padding: 0 0.625rem;
    margin-left: 30%;
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    .status-list-panel {
        margin-left: 0;
    }
    .progress-svg {
        width: 100%;
    }
}
.status-list-header {
    width: 100%;
    font-size: 17px;
    font-weight: bold;
    padding: 0.625rem 0.3125rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    cursor: pointer;
}

.status-list-header-title {
    width: 65%;
}

.status-title-stats {
    color: #666;
    font-weight: normal;
    padding-right: 10px;
}

.status-list-items {
    margin-top: 5px;
}

.status-list-item {
    padding: 5px 0;
    border-bottom: 1px solid #e4e4e4;
}

.status-list-item-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 5px;
}

.status-list-item-line {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.status-list-item-user {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 80%;
}

.status-list-item-user div {
    width: 25%;
}

.status-list-item-content {
    font-size: 13px;
    padding-left: 20px;
}


/*IMPORT PROGRESS*/
.progress-text {
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    color: black;
    font-size: 15px;
}


/*CSV READER*/
.csv-reader-group {
    position: relative;
    top: 3px;
}

.csv-reader-group > div {
    float: left;
}

.csv-reader-filename {
    padding-right: 10px;
}

.csv-reader-input {
    width: 0;
    height: 0;
}

.csv-reader-reset {
    width: 30px;
    position: relative;
    top: -5px;
    padding-right: 10px;
    font-size: 20px;
    text-align: right;
}

.users-import-example-csv-button {
    display: inline;
    border-radius: 5px;
    margin: 0 5px
}

.csv-reader-upload-button {
    border-radius: 5px;
    margin: 0;
}

.progress-svg {
    grid-column-start: 2;
    height: 3rem;
    width: 100%;
}



/*GRAPHS*/
.graphs-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2%;
    margin-bottom: 100px;
    padding-bottom: 30px;
}

.chart-container {
    position: relative;
    margin: 5px;
}

@media screen and (max-width: 1201px) {
    .chart-container {
        grid-column: 1 / span 2;
    }
}

.chart-container.fullsize {
    grid-column: 1 / span 2;
}

.chart-title {
    font-size: 125%;
    text-align: center;
    position: relative;
}


/*PBX REALIZATION*/
.pbx-realization {
    background-color: white;
    width: 60%;
    margin: 50px auto;
}

.pbx-realization-row {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: center;
}

.pbx-realization-icon {
    font-size: 6em;
    margin-right: 40px;
    width: 100px;
}

.pbx-realization-text {
    width: 70%;
}

.pbx-realization-text-waiting {
    color: #666;
    font-size: 2em;
    margin-bottom: 10px;
}

.pbx-realization-text-line {
    padding-left: 10px;
    color: #aaa;
}

.pbx-realization-timer {
    width: 100%;
    margin-top: 20px;
    color: #333;
    font-size: 2.5em;
    text-align: center;
}

.pbx-realization-timer-time {
    display: inline-block;
}

@media screen and (min-width: 1400px){
    .pbx-realization {
        padding: 100px;
        width: 55%;
    }
}

@media screen and (max-width: 1399px){
    .pbx-realization {
        padding: 75px;
    }
}

@media screen and (max-width: 1199px){
    .pbx-realization {
        padding: 50px;
        width: 70%;
    }
}

@media screen and (max-width: 999px){
    .pbx-realization {
        padding: 30px 15px;
        width: 75%;
    }
    .pbx-realization-text-waiting {
        font-size: 1.5em;
    }
    .pbx-realization-timer {
        font-size: 2em;
    }
    .pbx-realization-icon {
        margin-right: 10px;
    }
}

@media screen and (max-width: 799px){
    .pbx-realization {
        padding: 10px;
        width: 90%;
    }
    .pbx-realization-row {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }
}


/*CHAT BOT OVERRIDE*/
.feedbot-header {
    padding: 5px 5px 5px 16px !important;
}

.wc-app, .wc-app button, .wc-app input, .wc-app textarea{
    font-size: 0.8125rem !important;
}

.feedbot-wrapper {
    width: 400px !important;
    font-size: 0.8125rem;
}
